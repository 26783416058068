<div mat-dialog-title>
    Aanpassen
</div>
<div mat-dialog-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <mat-grid-list cols="1" rowHeight="80px">
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Aanbevolen prijs factor</mat-label>
                    <input matInput type="number" [formControl]="form.controls.recommendedPriceFactor" />
                    <mat-error *ngIf="form.controls.recommendedPriceFactor.touched && form.controls.recommendedPriceFactor.invalid"
                        color="warn">De Aanbevolen prijs factor is ongeldig.</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Aankoopprijs overschrijven</mat-label>
                    <input matInput type="number" [formControl]="form.controls.purchasePriceOverride" />
                    <mat-error *ngIf="form.controls.purchasePriceOverride.touched && form.controls.purchasePriceOverride.invalid"
                        color="warn">De Aankoopprijs overschrijven is ongeldig.</mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field>
                    <mat-label>Bol break-even prijs</mat-label>
                    <input matInput type="number" [formControl]="form.controls.bolBreakEvenPrice" />
                    <mat-error *ngIf="form.controls.bolBreakEvenPrice.touched && form.controls.bolBreakEvenPrice.invalid"
                        color="warn">De Bol break-even prijs is ongeldig.</mat-error>
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>

        <div class="action-buttons" >
            <button *ngIf="isReadOnly === false" mat-raised-button type="submit" color="primary">Opslaan</button>
            <button mat-raised-button [mat-dialog-close]>Annuleren</button>
        </div>
    </form>
</div>

import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AUTH_SERVICE, AuthService } from "../../../services/auth/auth.service";
import { DialogMode } from "../../../shared/enums/dialog-mode";
import { DialogResult } from "../../../shared/enums/dialog-result";
import { SuppliersProductsEditDialogData } from "./suppliers-products-edit-dialog-data";
import { SuppliersProductsEditDialogForm } from "./suppliers-products-edit-dialog-form";
import { SuppliersProductsEditDialogResult } from "./suppliers-products-edit-dialog-result";

@Component({
  selector: "app-suppliers-products-edit-dialog",
  templateUrl: "./suppliers-products-edit-dialog.component.html",
  styleUrl: "./suppliers-products-edit-dialog.component.scss"
})
export class SuppliersProductsEditDialogComponent {
    protected readonly form: FormGroup<SuppliersProductsEditDialogForm>;
    protected readonly isReadOnly: boolean = true;

    constructor(
        private readonly dialogRef: MatDialogRef<SuppliersProductsEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: SuppliersProductsEditDialogData,
        @Inject(AUTH_SERVICE) readonly authService: AuthService,
    ) {
        if (data.mode === DialogMode.Edit && authService.hasScope("pim:supplier-products:u")) {
            this.isReadOnly = false;
        }

        this.form = new FormGroup<SuppliersProductsEditDialogForm>({
            recommendedPriceFactor: new FormControl({ value: data.recommendedPriceFactor, disabled: this.isReadOnly }, { nonNullable: true }),
            purchasePriceOverride: new FormControl({ value: data.purchasePriceOverride, disabled: this.isReadOnly }, { nonNullable: true }),
            bolBreakEvenPrice: new FormControl({ value: data.bolBreakEvenPrice, disabled: this.isReadOnly }, { nonNullable: true }),
        });
     }

    protected submit(): void {
        this.form.markAllAsTouched();

        if (this.form.valid) {
            const result: SuppliersProductsEditDialogResult = {
                dialogResultMode: DialogResult.Updated,
                id: this.data.id,
                recommendedPriceFactor: this.form.controls.recommendedPriceFactor.value,
                purchasePriceOverride: this.form.controls.purchasePriceOverride.value,
                bolBreakEvenPrice: this.form.controls.bolBreakEvenPrice.value,
            };

            this.dialogRef.close(result);
        }
    }
}
